<template>
  <div style="width: 150px; height: 150px">
    <div
      class="file__small"
      :style="`background-image: url('${serverUrl + file}')`"
    >
      <img @click="openModal" src="@/assets/image/eye.svg" />
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="none">
      <img class="show__photos" :src="getImage" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ['file', 'notRedact'],
  data () {
    return {
      previewVisible: false,
      serverUrl: process.env.VUE_APP_SERVER_URL_FILE
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    openModal () {
      this.previewVisible = true
    }
  },
  computed: {
    getImage () {
      if (this.file.image) {
        return process.env.VUE_APP_SERVER_URL_FILE + this.file.image
      }
      return process.env.VUE_APP_SERVER_URL_FILE + this.file
    }
  }
}
</script>
<style lang="scss">
.show__photos {
  margin-top: 20px;
  width: 100%;
  max-width: 860px;
  height: 100%;
  background-size: cover;
}
.file__small {
  background-size: cover;
  background-position: 20%;
  max-width: 170px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-bottom: 30px;
  flex: 1 0 25%;
  // padding: 44px;

  transform: translate(0, 30%);
  // opacity: 0;
  transition: 0.3s;
  &._active {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0.3s ease 0.1s;
  }
  &:hover {
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        180deg,
        rgb(112, 112, 112) 13.24%,
        transparent 118.5%
      );
      opacity: 0.75;
    }

    img {
      opacity: 1;
      z-index: 3;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  img {
    opacity: 0;
  }
}
</style>
