<template>
  <div class="chat__input__message">
    <Avatar :file="avatar"></Avatar>
    <div class="chat__input__message__container">
      <a-textarea
        :value="draftMessage.text"
        v-on:input="updateMessage($event)"
        placeholder="Ваше сообщение"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        class="chat__input__message__container__text__area"
      />
    <div class="chat__input__message__container__buttons">
      <div class="chat__input__message__container__button__send" style="height : 40px">
        <Button @click="sendMessage" class="red-antd-button-without-width">
            Ответить
          </Button>
      </div>
        <UploadFiles
          name="Загрузить Фото"
          v-bind:files="draftMessage.files"
          @transferFiles="updateFiles"
        ></UploadFiles>
      </div>
    </div>
  </div>
</template>
<script>
import { getPersonAvatar, getBearer } from '@/helpers/authHelper'
import axios from 'axios'

import Button from '@/components/common/Button'
import Avatar from '@/components/common/Avatar.vue'
import UploadFiles from '@/components/common/UploadFiles'

export default {
  props: ['tenderId', 'userId'],
  components: {
    Button,
    UploadFiles,
    Avatar
  },
  data () {
    return {
      serverPath: process.env.VUE_APP_SERVER_URL,
      draftMessage: {
        files: []
      }
    }
  },
  methods: {
    updateFiles (list) {
      this.draftMessage.files = list
    },
    updateMessage (e) {
      this.draftMessage.text = e.target.value
    },

    getSendData () {
      if (this.draftMessage.files.length === 0) {
        return {
          userId: this.userId,
          tenderId: this.tenderId,
          text: this.draftMessage.text
        }
      } else {
        return {
          userId: this.userId,
          tenderId: this.tenderId,
          text: this.draftMessage.text,
          files: this.draftMessage.files.map(this.getUrlAdressFromFile)
        }
      }
    },
    getUrlAdressFromFile (file) {
      return file.urlName
    },
    sendMessage () {
      if (!this.draftMessage.text) {
        // alert('write message')
        return
      }

      axios
        .post(
          process.env.VUE_APP_SERVER_URL + '/messages',
          this.getSendData(),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getBearer()
            }
          }
        )
        .then((res) => {
          this.draftMessage.files = []
          this.draftMessage.text = ''
          console.log('SUCCESS!!', res)
          this.$store.dispatch('setMessages', {
            tenderUUID: this.tenderId,
            userUUID: this.userId
          })
          this.$forceUpdate()
        })
        .catch(function (err) {
          console.log('FAILURE!!', err)
        })
    }
  },
  computed: {
    avatar () {
      return getPersonAvatar()
    }
  }
}
</script>

<style lang="scss">
.chat__input__message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  &__avatar {
    border-radius: 50%;
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    &__empty {
      border-radius: 50%;
      background-color: rgb(212, 208, 208);
      height: 54px;
      width: 54px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__icon {
        height: 34px;
        width: 34px;
      }
    }
  }
  &__container {
    width: 100%;
    &__text__area {
      resize: none;
      width: 80%;
    }
    &__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      margin-top: 12px;
      .upload-content {
        margin-top: 0px;
        width: 100%;
      }
      .white-antd-button-border {
        height: 40px;
        width: auto;
      }
    }
    &__button__send {
      margin-right: 12px;
      height: 40px;
      width: auto;
    }
  }
  &__personal__avatar {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    background-size: cover;
  }
}
</style>
