<template>
  <div>
    <div v-if="!isOpenMasterProfile" class="chat__container">
      <Dialog
        v-if="response"
        :chat="response"
        @clickDialog="closeDialogEvent()"
        :isSingle="true"
        @openMasterProfile="openMasterProfile"
      ></Dialog>
      <div
        v-if="this.$store.getters.getMessagesLoaded"
        class="chat__container__messages"
      >
        <Message
          v-for="message in this.$store.getters.getMessages"
          :key="message.uuid"
          :message="message"
        ></Message>
      </div>
      <InputMessage :tenderId="tenderUUID" :userId="userUUID"></InputMessage>
    </div>
    <div v-if="isOpenMasterProfile" class="chat__container">
      <MasterProfile
        @closeMasterProfile="openMasterProfile"
        :userUUID="userUUID"
      ></MasterProfile>
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Chat/Dialog.vue'
import Message from '@/components/Chat/Message.vue'
import InputMessage from '@/components/Chat/InputMessage.vue'
import MasterProfile from '@/components/CustomerComponents/MasterProfile.vue'

export default {
  props: ['response', 'tenderUUID', 'userUUID'],
  mounted () {
    this.$store.dispatch('setMessages', {
      tenderUUID: this.tenderUUID,
      userUUID: this.userUUID
    })
  },
  components: {
    Dialog,
    Message,
    InputMessage,
    MasterProfile
  },
  data () {
    return {
      isOpenMasterProfile: false
    }
  },
  methods: {
    closeDialogEvent () {
      this.$emit('closeDialogEvent')
    },
    openMasterProfile () {
      this.isOpenMasterProfile = !this.isOpenMasterProfile
    }
  }
}
</script>

<style lang="scss">
.chat__container {
  background-color: white;
  padding: 39px;
  &__messages {
    max-height: 600px;
    overflow-y: auto;
    width: 100%;
  }
}
</style>
