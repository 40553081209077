<template>
  <div class="files">
    <div v-if="files.length > 0" class="files__image__container">
      <FileSmall
        :noRedact="noRedact"
        v-for="file in files"
        :key="file"
        :file="file"
      ></FileSmall>
    </div>
  </div>
</template>

<script>
import FileSmall from '@/components/common/FileSmall.vue'

export default {
  props: ['files', 'noRedact'],
  components: {
    FileSmall
  },

  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = !this.isModalVisible
    }
  }
}
</script>

<style lang="scss">
.files {
  &__image__container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 35px;
  }
}
</style>
