<template>
  <div
    class="master__profile__visible"
    v-if="this.$store.getters.getMasterProfileLoaded"
  >
    <div class="master__profile__visible__wrapper">
      <div class="master__profile__visible__header">
        <Avatar :file="this.$store.getters.getMasterProfile.image"></Avatar>
        <div class="master__profile__visible__labels">
          <div class="master__profile__visible__label__main">
            <p>{{ this.$store.getters.getMasterProfile.name }}</p>
            <p>{{ this.$store.getters.getMasterProfile.surname }}</p>
            <p>{{ this.$store.getters.getMasterProfile.middlename }}</p>
          </div>
          <div class="master__profile__visible__label__desc">
            {{ this.$store.getters.getMasterProfile.description }}
          </div>
        </div>
      </div>
      <div @click="closeMasterProfile()">
        <img src="@/assets/image/arrow-red.svg" />
      </div>
    </div>

    <div class="master__profile__visible__personal__data">
      <div class="master__profile__visible__personal__data__cell">
        <div
          class="master__profile__visible__personal__data__cell__hide__label"
        >
          Стаж работы
        </div>
        <div
          class="master__profile__visible__personal__data__cell__bold__label"
        >
          {{ this.$store.getters.getMasterProfile.workExperience }}
        </div>
      </div>
<!--      <div class="master__profile__visible__personal__data__cell">-->
<!--        <div-->
<!--          class="master__profile__visible__personal__data__cell__hide__label"-->
<!--        >-->
<!--          Почта-->
<!--        </div>-->
<!--        <div-->
<!--          class="master__profile__visible__personal__data__cell__bold__label"-->
<!--        >-->
<!--          ?-->
<!--        </div>-->
<!--        &lt;!&ndash; <div>{{ this.$store.getters.getMasterProfile.email }}</div> &ndash;&gt;-->
<!--      </div>-->
<!--      <div class="master__profile__visible__personal__data__cell">-->
<!--        <div-->
<!--          class="master__profile__visible__personal__data__cell__hide__label"-->
<!--        >-->
<!--          Номер Телефона-->
<!--        </div>-->
<!--        <div-->
<!--          class="master__profile__visible__personal__data__cell__bold__label"-->
<!--        >-->
<!--          ?-->
<!--        </div>-->
<!--        &lt;!&ndash; <div>{{ this.$store.getters.getMasterProfile.phone }}</div> &ndash;&gt;-->
<!--      </div>-->
      <div
        class="master__profile__visible__personal__data__cell"
        v-if="this.$store.getters.getMasterProfile.companyName"
      >
        <div
          class="master__profile__visible__personal__data__cell__hide__label"
        >
          Компания
        </div>
        <div
          class="master__profile__visible__personal__data__cell__bold__label"
        >
          {{ this.$store.getters.getMasterProfile.companyName }}
        </div>
      </div>
    </div>
    <div class="master__profile__visible__profile">
      <div class="master__profile__visible__profile__title">Профиль работ</div>
      <ServiceWork
        v-for="subtag in this.$store.getters.getMasterProfile.subtags"
        :key="subtag.id"
        :name="subtag.name"
        :color="subtag.color"
      />
      <ServiceWork />
    </div>
    <a-tabs @tabClick="show">
      <a-tab-pane @tabClick="show" key="1" tab="Портфолио">
        <div style="margin-left: -20px">
          <Portfolio
            v-if="this.$store.getters.getMasterProfileLoaded"
            :portfolio="this.$store.getters.getMasterProfile.portfolios"
          ></Portfolio>
        </div>
        <div class="pagination">
          <a-pagination
            :current="currentPageActivePortfolio"
            :total="this.$store.getters.getTotalMasterWatchedPortfolios"
            :showLessItems="true"
            :defaultPageSize="6"
            show-less-items
            @change="onChangePortfolioPagination"
          />
          <!-- :hideOnSinglePage="true" -->
        </div>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Отзывы">
        <div
          style="margin-top: 40px"
          v-if="this.$store.getters.getMasterProfileLoaded"
        >
          <Review
            v-for="review in this.$store.getters.getMasterProfile.reviews.data"
            :key="review.id"
            :review="review"
          ></Review>
        </div>
        <div class="pagination">
          <a-pagination
            :current="currentPageActiveReviews"
            :total="this.$store.getters.getTotalMasterWatchedReviews"
            :showLessItems="true"
            show-less-items
            @change="onChangeReviewPagination"
          />
        </div>
        <!-- :hideOnSinglePage="true" -->
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Portfolio from '@/components/CustomerComponents/Portfolio.vue'
import Review from '@/components/CustomerComponents/Review.vue'
import ServiceWork from '@/components/common/ServiceWork.vue'
import Avatar from '@/components/common/Avatar.vue'
import { getImageUrl } from '@/helpers/image'
export default {
  props: ['userUUID'],
  components: {
    Portfolio,
    Review,
    ServiceWork,
    Avatar
  },

  data () {
    return {
      profile: this.$store.getters.getMasterProfile,
      currentPageActivePortfolio: 1,
      currentPageActiveReviews: 1
    }
  },
  mounted () {
    this.$store.dispatch('setMasterProfile', {
      userUUID: this.userUUID,
      page: 0
    })
  },
  methods: {
    show (e) {
      if (e === 1) {
        this.onChangePortfolioPagination(this.currentPageActivePortfolio)
      }
      if (e === 2) {
        this.onChangeReviewPagination(this.currentPageActiveReviews)
      }
    },
    closeMasterProfile () {
      this.$emit('closeMasterProfile')
    },
    fullName (obj) {
      return obj.name + obj.surname + obj.middlename
    },
    getAvatarImageUrl (obj) {
      return getImageUrl(obj)
    },
    onChangePortfolioPagination (current) {
      this.currentPageActivePortfolio = current
      this.$store.dispatch('setMasterProfile', {
        userUUID: this.userUUID,
        page: this.currentPageActivePortfolio
      })
    },
    onChangeReviewPagination (current) {
      this.currentPageActiveReviews = current
      this.$store.dispatch('setMasterProfile', {
        userUUID: this.userUUID,
        page: this.currentPageActiveReviews
      })
    }
  }
}
</script>

<style lang="scss">
.master__profile__visible {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    margin-bottom: 21px;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__labels {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__label {
    &__main {
      display: flex;
      flex-wrap: wrap;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      p {
        margin-bottom: 0.2em;
      }
    }
    &__desc {
      //styleName: Body 14/regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  &__personal__data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 20px;
    &__cell {
      display: flex;
      flex-direction: column;
      margin-right: 49px;
      &__hide__label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #989898;
      }
      &__bold__label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  &__profile {
    &__title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
</style>
