<template>
  <div class="dialog">
    <div class="dialog__header__chat__container">
      <div style="display: flex">
        <Avatar @clickAvatar="openMasterProfile" :file="this.chat.user.image"></Avatar>
        <div>
          <div class="dialog__header__chat__content">
            <div class="dialog__header__chat__user__name__container">
              <div class="dialog__header__chat__user__name__label">{{ userName }}</div>
              <div class="dialog__header__chat__grey__label">{{ requestDay }}</div>
            </div>

            <div class="dialog__header__chat__price__time__cell">
              <div class="dialog__header__chat__price__work__cell">
                <div class="dialog__header__chat__grey__label">Стоимость</div>
                <div>{{ priceWork }}</div>
              </div>
              <div class="dialog__header__chat__price__time__work__cell">
                <div class="dialog__header__chat__grey__label">Срок работ</div>
                <div>{{ workDays }}</div>
              </div>
            </div>

            <div class="dialog__header__chat__description">
              {{ chat.text }}
            </div>
            <div class="dialog__files">
              <FilesSmall :files="chat.files"></FilesSmall>
            </div>
          </div>
        </div>
      </div>
<!--      <div v-if="!isSingle" class="dialog__chat__button__open">-->
<!--        <div @click="openDialog()">-->
<!--          <a-badge-->
<!--            class="dialog__chat__button__open__badge"-->
<!--            :number-style="{ backgroundColor: '#B12021' }"-->
<!--            :count="isUnreadMessages ? `new!` : ''"-->
<!--          >-->
<!--           <img src="@/assets/image/chatIcon.svg" />-->
<!--            <a href="#" class="head-example" />-->
<!--          </a-badge>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div  class="dialog__chat__button__close">-->
<!--        <div @click="closeDialog()">-->
<!--          <img src="@/assets/image/arrow-red.svg" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <slot></slot>
    <div v-if="!isSingle" class="dialog__profile__buttons">
      <div class="dialog__profile__button">
        <div style="height: 40px; margin-left 20px;">
<!--          <Button class="red-antd-button" @click="openMasterProfile">-->
<!--            <img-->
<!--              src="@/assets/image/profile-white.svg"-->
<!--            />-->
<!--            Посмотреть профиль-->
<!--          </Button>-->
                    <Button v-if="!isSingle" class="red-antd-button" @click="openDialog()">
                      <img
                        src="@/assets/image/profile-white.svg"
                      />
                      Перейти в чат
                    </Button>
        </div>
      </div>
      <div
        v-if="this.$store.getters.getTender.status.id === 1"
        class="dialog__profile__button"
      >
        <Button class="white-antd-button-border" @click="openWinnerModal">
          Выбрать исполнителем
        </Button>
      </div>
      <a-modal :visible="isModal" :footer="null" @cancel="openWinnerModal">
        <div class="dialog__winner__modal">
          <Button
            buttonClass="red-antd-button"
            :style="'height: 40px; margin-right: 15px;'"
            @click="nominateWinner"
          >
            Выбрать исполнителем?
          </Button>
          <Button class="white-antd-button-border" @click="openWinnerModal">
            Отмена
          </Button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'
import Avatar from '@/components/common/Avatar.vue'
import axios from 'axios'
import { getBearer, isMaster, isCustomer, getUUID } from '@/helpers/authHelper'
import FilesSmall from '@/components/common/FilesSmall'

export default {
  props: ['chat', 'isSingle'],
  components: {
    Button,
    FilesSmall,
    Avatar
    // ReviewModal
  },
  data () {
    return {
      isModal: false,
      priceWork:
        'от ' +
        this.chat.planned_start_price +
        ' руб. до ' +
        this.chat.planned_end_price +
        ' руб.',
      requestDay: this.chat.created_at,
      workDays:
        this.chat.planned_start_date + ' - ' + this.chat.planned_end_date,
      userName: this.chat.user.name + ' ' + this.chat.user.surname,
      userImage: process.env.VUE_APP_SERVER_URL + this.chat.user.image
    }
  },
  computed: {
    isUnreadMessages () {
      if (isMaster() && this.chat.new_message_user === 1) {
        return true
      }
      if (isCustomer() && this.chat.new_message_customer === 1) {
        return true
      }
      return false
    }
  },
  methods: {
    openDialog () {
      if (this.isUnreadMessages) {
        let sendData
        if (isMaster()) {
          sendData = {
            userId: getUUID(),
            tenderId: this.chat.tender.uuid
          }
        } else {
          sendData = {
            userId: this.chat.user.uuid,
            tenderId: this.chat.tender.uuid
          }
        }
        axios
          .post(process.env.VUE_APP_SERVER_URL + '/read-all', sendData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getBearer()
            }
          })
          .then((res) => {
            this.$notification.open({
              message: 'Вы прочитали все сообщения'
            })
          })
          .catch(function (err) {
            this.$notification.open({
              message: 'Запрос на прочтение сообщений не прошел',
              description: err
            })
          })
      }
      this.$emit('clickDialog')
    },
    closeDialog () {
      this.$emit('clickDialog')
    },
    openMasterProfile () {
      this.$emit('openMasterProfile')
    },
    openWinnerModal () {
      this.isModal = !this.isModal
    },
    nominateWinner () {
      axios
        .post(
          process.env.VUE_APP_SERVER_URL + '/nominate-winner',
          {
            tender_uuid: this.chat.tender.uuid,
            user_uuid: this.chat.user.uuid
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getBearer()
            }
          }
        )
        .then((res) => {
          this.$notification.open({
            message: 'Вы выбрали исполнителя!'
          })
          this.openWinnerModal()
        })
        .catch(function (err) {
          this.$notification.open({
            message: 'Вы не смогли выбрать исполнителя тендера!',
            description: err
          })
          this.openWinnerModal()
        })
    }
  }
}
</script>

<style lang="scss">
.dialog {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 33px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  &__header__chat {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
      width: 100%;
    }
    &__content {
      display: flex;
      flex-direction: column;

      padding-left: 12px;
      width: 100%;
    }
    &__user__name__container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
&__price__time__cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 7px;
}
&__price__work__cell {
  display: flex;
  flex-direction: column;
}
&__price__time__work__cell {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

&__grey__label {
  min-width: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #989898;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

&__user__name__label {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: black;

  padding-right: 12px;
  padding-bottom: 10px;

  align-items: center;
}
  }
  &__chat__button__open {
    display: flex;
    flex-direction: row;
    color: #b12021;
    align-items: flex-start;
    cursor: pointer;
    img {
      left: -5px;
      position: relative;

      height: 34px;
      width: 34px;
    }
    &__badge {
      position: relative;
      top: 10px;
    }
  }

  &__profile__buttons {
    display: flex;
    margin-left: 66px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }
  &__profile__button {
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  &__winner__modal {
    padding: 30px 20px;
    height: 100%;
    width: 100%;
    border-radius: 3px;

    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__files {
    margin-left: -20px;
  }
}
@media (max-width: 1200px) {
  .dialog {
    &__header__chat__user__name__container {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 992px) {
  .dialog {
    &__header__chat__price {
      &__time__cell {
        flex-direction: column;
        margin-bottom: 30px;
      }
      &__time__work__cell{
        margin-left: 0px;
      }
      &__work__cell{
         margin-bottom: 15px;
      }
    }
  }
}
</style>
