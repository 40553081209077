<template>
  <div class="master__portfolios">
    <div class="master__portfolios__image__container">
      <file-big
        v-for="file in portfolio.data"
        :key="file"
        :file="file.image"
        :notRedact="true"
      ></file-big>
    </div>
    <a-modal
      :footer="null"
      :visible="isModalVisible"
      @cancel="showModal"
      :closable="false"
    >
      <PortfolioModal
        v-show="isModalVisible"
        @close="showModal"
      ></PortfolioModal>
    </a-modal>
  </div>
</template>

<script>
import PortfolioModal from '@/components/modal/PortfolioModal.vue'
import FileBig from '@/components/common/FileBig.vue'

export default {
  props: ['portfolio'],
  components: {
    PortfolioModal,
    FileBig
  },

  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = !this.isModalVisible
    }
  }
}
</script>

<style lang="scss">
.master__portfolios {
  background-color: white;
  &__image__container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
}
</style>
