<template>
  <div class="chat__message">
    <Avatar :file="message.from_user.image"></Avatar>
    <div class="chat__message__container">
      <div class="chat__message__container__header">
        <div class="chat__message__container__header__name">
          {{ message.from_user.name }} {{ message.from_usersurname }}
        </div>
        <div class="chat__message__container__header__created">
          {{ message.created_at }}
        </div>
      </div>
      <div class="chat__message__container__text">{{ message.text }}</div>
      <div class="chat__message__container__images">
        <FileSmall
          v-for="file in message.files"
          :key="file"
          :file="file"
        ></FileSmall>

        <a-modal :visible="previewVisible" :footer="null">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import FileSmall from '@/components/common/FileSmall.vue'
import Avatar from '@/components/common/Avatar.vue'

export default {
  props: ['message'],
  components: {
    FileSmall,
    Avatar
  },
  data () {
    return {
      previewVisible: false,
      previewImage: ''
    }
  },
  methods: {
    getImage (imageName) {
      return process.env.VUE_APP_SERVER_URL_FILE + imageName
    }
  }
}
</script>

<style lang="scss">
.chat__message {
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  &__avatar {
    border-radius: 50%;
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    &__personal {
      border-radius: 50%;
      height: 54px;
      width: 54px;
    }
    &__empty {
      background-color: rgb(223, 223, 223);
      border-radius: 50%;
      height: 54px;
      min-width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      &__name {
        margin-right: 10px;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      &__created {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #989898;
      }
    }
    &__text {
      margin-top: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin-right: 15px;
    }
    &__images {
      margin-top: 15px;
      margin-left: -20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
    }
    &__image {
      margin-right: 10px;
      &::before {
        z-index: 2;
        background-color: black;
      }
      img {
        height: 54px;
        width: 54px;
      }
    }
  }
}
</style>
